export const servicesData = [
  {
    _id: 121,
    name: "Art Direction",
    line: "Creating a consistent visual strategy that align with your brand identity and enhance user experience.",
    skills: [
      "Design principles",
      "Strategy",
      "Storytelling",
      "Concept",
      "Visual Consistency",
      "Project Management",
    ],
  },
  {
    _id: 122,
    name: "Graphic Design",
    line: "Crafting visually compelling graphics and imagery to effectively communicate your brand's message.",
    skills: [
      "Logo Design",
      "Branding",
      "Identity",
      "Color schemes",
      "typography",
      "Photo Editing",
      "Icons",
      "Illustrations",
    ],
  },
  {
    _id: 123,
    name: "Web Design",
    line: "Designing visually appealing and user-friendly interfaces, to deliver an exceptional and memorable user experience.",
    skills: [
      "UI/UX",
      "design process",
      "User-reseach",
      "User-needs",
      "Prototype",
      "User-testing",
    ],
  },
  {
    _id: 124,
    name: "Web Developement",
    line: "Building and coding functional, responsive websites to ensure seamless performance and usability.",
    skills: [
      "front End",
      "HTML 5",
      "CSS 3",
      "SASS",
      "SEO",
      "JavaScript",
      "React js",
      "Problem Solving",
    ],
  },
];
